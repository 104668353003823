<template>
  <div :class="props.class">
    <label v-if="label" class="block text-sm mb-2"
      >{{ label
      }}<span
        v-if="props.required"
        :class="{ 'text-red-500': requireHighlight }"
        >*</span
      ></label
    >
    <slot :handle-event="onChildFocus" :is-error="Boolean(errorMessage)" />
    <div
      v-if="props.showErrorMessage"
      style="color: red"
      class="text-sm text-right relative"
    >
      <span
        class="absolute"
        :class="{
          'right-0': props.errorPosition === 'right',
          'left-0': props.errorPosition === 'left',
        }"
        style="top: 3px"
        >{{ formattedMessage }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import EventBus from '~/event-bus';

const props = defineProps({
  target: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    required: false,
  },
  showErrorMessage: {
    type: Boolean,
    required: false,
    default: true,
  },
  formId: {
    type: String,
    required: false,
    default: '',
  },
  class: {
    type: [String, Array, Object] as PropType<
      string | string[] | Record<string, boolean>
    >,
    required: false,
    default: 'mb-3',
  },
  errorPosition: {
    type: String,
    default: 'right',
  },
});

const requireHighlight = ref(true);
const errorMessage = ref('');

const handleFormError = (event: any) => {
  errorMessage.value = event.message;
};

const handleFormErrorRequired = (event: any) => {
  if (event.validField) {
    requireHighlight.value = false;
  } else {
    requireHighlight.value = true;
  }
};

const formattedMessage = computed(() => {
  if (errorMessage.value) {
    // let message = errorMessage.value;
    // console.log('message', message);

    // if (message.includes('Expected')) {
    //   message = 'This field is required';
    // }
    const formattedError = errorMessage.value.replace(
      /([a-z])([A-Z])/g,
      '$1 $2'
    );
    if (formattedError.includes('Expected')) {
      return 'field is required';
    }

    if (formattedError.includes('N/A')) {
      return formattedError;
    } else {
      return formattedError.toLowerCase();
    }
  } else {
    return '';
  }
});

onMounted(() => {
  if (props.target) {
    EventBus.on(props.formId + props.target, handleFormError);
    if (props.required) {
      EventBus.on(
        props.formId + props.target + 'required',
        handleFormErrorRequired
      );
    }
  }
});

onUnmounted(() => {
  if (props.target) {
    EventBus.off(props.formId + props.target, handleFormError);
  }
  if (props.required) {
    EventBus.off(
      props.formId + props.target + 'required',
      handleFormErrorRequired
    );
  }
});

const onChildFocus = () => {
  // Emit a custom event to notify the parent component
  // that the child component is focused
  // You can pass any additional data as needed
  // For example, you can pass the child component's name or ID
  // using the event payload
  EventBus.emit(props.formId + 'form', { target: props.formId + props.target });
};

defineExpose({
  onChildFocus,
});
</script>
